/**
 * 手机号码正则
 * params str 手机号码字符串
 */
function isValidPhone (str) {
  const reg = /^1[3|4|5|7|8][0-9]\d{8}$/
  return reg.test(str)
}

function isValidIDNumber (str) {
  const reg = /^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/
  return reg.test(str)
}
/**
 * 最多两位小数数字
 * params str 数字字符串
 */
function isValidFloat2 (str) {
  const reg = /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/
  return reg.test(str)
}

export default {
  isValidPhone: isValidPhone,
  isValidIDNumber: isValidIDNumber,
  isValidFloat2: isValidFloat2
}
