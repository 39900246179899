<template>
  <div class="box wrapper">
    <el-breadcrumb separator="/" class="el-breadcrumb">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/EnterpriseService' }">政务服务</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/StaffNeedList' }">园区企业用工需求</el-breadcrumb-item>
      <el-breadcrumb-item>企业信息</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form ref="saveForm" :model="saveData" :rules="saveRules" @submit.native.prevent label-width="96px" label-position="right">
      <div class="content">
        <div class="title">
          <div class="line"></div>
          <div class="text">企业信息</div>
        </div>
        <el-form-item label="对外展示名称" style="margin-top: 26px">
          <el-input v-model="saveData.companyName" placeholder="不填则默认为企业名称" :maxlength="20"></el-input>
        </el-form-item>
        <el-form-item label="联系人" prop="name">
          <el-input v-model="saveData.name" placeholder="请输入联系人" :maxlength="10"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input v-model.number="saveData.phone" placeholder="请输入手机号" :maxlength="11"></el-input>
        </el-form-item>
      </div>
      <div class="content">
        <div class="title">
          <div class="line"></div>
          <div class="text">招工信息</div>
        </div>
        <div class="item" v-for="(item, index) in saveData.jobInformationListStr" :key="index">
          <div class="tip">职位{{ index + 1 }}</div>
          <el-row :gutter="40" style="margin-bottom: 7px">
            <el-col :span="12">
              <el-form-item
                label="职位名称"
                :prop="'jobInformationListStr.' + index + '.job_name'"
                :rules="[{ required: true, message: '请输入职位名称', trigger: 'blur' }]"
              >
                <el-input v-model="item.job_name" placeholder="请输入职位名称" :maxlength="20"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="薪资待遇"
                :prop="'jobInformationListStr.' + index + '.salary'"
                :rules="[{ required: true, message: '请输入薪资待遇', trigger: 'blur' }]"
              >
                <el-input v-model="item.salary" placeholder="请输入薪资待遇" :maxlength="20"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          
          <el-row :gutter="40" style="margin-bottom: 7px">
            <el-col :span="12">
              <el-form-item
                label="学历要求"
                :prop="'jobInformationListStr.' + index + '.jobRequirements'"
                :rules="[{ required: true, message: '请输入学历要求', trigger: 'blur' }]"
              >
               <el-input v-model="item.jobRequirements" placeholder="请输入学历要求" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="岗位数量"
                :prop="'jobInformationListStr.' + index + '.numberRecruit'"
                :rules="[{ required: true, message: '请输入岗位数量', trigger: 'blur' }]"
              >
                <el-input v-model="item.numberRecruit" type="number" placeholder="岗位数量" ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- <el-row>
            <el-form-item
              label="职位要求"
              :prop="'jobInformationListStr.' + index + '.job_requirements'"
              :rules="[{ required: true, message: '请输入职位需求', trigger: 'blur' }]"
            >
              <el-input type="textarea" v-model="item.job_requirements" :rows="3" placeholder="请输入职位要求" :maxlength="500"></el-input>
            </el-form-item>
          </el-row> -->
          <el-button type="primary" plain class="add btn" @click="add"  v-if="index == 0"
            >新增</el-button
          >
          <el-button type="danger" plain class="" @click="deletes(index)" v-else>删除</el-button>
        </div>
        <el-button type="primary" class="save" @click="save">发布职位</el-button>
        <el-button @click="cancel">取消</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import validate from '@/assets/js/validate';
export default {
  name: 'policyService',
  data() {
    let validatorName = (rule, value, callback) => {
      console.log(value, validate);
      if (value === '' || value === undefined) {
        callback(new Error('请输入手机号'));
        return;
      }
      if (validate.isValidPhone(value)) {
        callback();
      } else {
        callback(new Error('正确的手机号'));
      }
    };
    return {
      saveData: {
        companyName: this.$store.state.userStore.adminVo.companyName,
        name: '',
        phone: '',
        jobInformationListStr: [
          {
            job_name: '',
            salary: '',
            job_requirements: '',
          },
        ],
      },
      saveRules: {
        name: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
        phone: [{ required: true, validator: validatorName, trigger: 'blur' }],
      },
      id: 0,
    };
  },
  methods: {
    add() {
      this.saveData.jobInformationListStr.push({
        job_name: '',
        salary: '',
        job_requirements: '',
        jobRequirements:'',//学历要求
        numberRecruit:'',//招聘人数
        company_id: this.id,
      });
    },
    deletes(i) {
      this.saveData.jobInformationListStr.splice(i, 1);
    },
    save() {
      this.$refs['saveForm'].validate((valid) => {
        if (valid) {
          let fn = this.id!=0 ? this.$api.editcorporateinfo : this.$api.addcorporateinfo;
          let params = JSON.parse(JSON.stringify(this.saveData));
          console.log("数据发布 提交",params)
          params.id = this.id;
          params.jobInformationListStr = JSON.stringify(params.jobInformationListStr);
       
          fn(params).then((res) => {
            if (res.success) {
              this.$router.go(-1);
              this.$message.success({ message: '发布成功', offset: 80 });
            } else {
              this.$message.error({ message: res.message, offset: 80 });
            }
          });
        } else {
          return false;
        }
      });
    },
    cancel() {
      this.$router.go(-1);
    },
  },
  created() {
    this.id = this.$route.params.id || '';
    if (this.id != 0) {
      this.$api.getcorporateinfo(this.id).then((res) => {
        this.saveData = res.result.corporateInformation;
        res.result.list = res.result.list && res.result.list.length > 0 ? res.result.list : [{ job_name: '', salary: '', job_requirements: '',number_recruit:0 }];
        res.result.list =  res.result.list.map(item=>{
          item.jobRequirements = item.job_requirements;
          item.numberRecruit = item.number_recruit
          return item;
        })
        this.$set(this.saveData, 'jobInformationListStr', res.result.list);
      });
    }
  },
};
</script>

<style lang='scss' scoped>
.box {
  .content {
    padding: 20px;
    background-color: #fff;
    padding-bottom: 8px;
    .title {
      display: flex;
      align-items: center;
      padding-bottom: 18px;
      border-bottom: 1px solid #dddddd;
      margin-bottom: 20px;
      .line {
        width: 4px;
        height: 20px;
        background: #4393f8;
        border-radius: 3px;
        margin-right: 10px;
      }
      .text {
        font-size: 26px;
        color: #333333;
        line-height: 36px;
        font-weight: 600;
      }
    }
    .item {
      background: #f7f7f7;
      border-radius: 4px;
      position: relative;
      padding: 56px 20px 20px;
      margin-bottom: 16px;
      .tip {
        position: absolute;
        top: 0;
        left: 0;
        width: 108px;
        height: 36px;
        text-align: center;
        line-height: 36px;
        font-size: 16px;
        color: #333333;
        background: url('../../../assets/img/img_zw.png') center no-repeat;
      }
      .add {
        border: 1px solid #135694;
        color: #135694;
      }
      .btn {
        margin-left: 96px;
        color: #fff !important;
      }
      .add:hover,
      .add:active {
        background-color: #135694 !important;
        color: #fff !important;
      }
      .add:focus {
        border: 1px solid #135694 !important;
        color: #135694 !important;
        background: #ecf5ff !important;
      }
    }
  }
  .content:nth-last-of-type(1) {
    margin-top: 16px;
  }
  .save {
    margin: 30px 24px 21px 116px;
  }
}
</style>
